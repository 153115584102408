.Layout {
    width: 100%;
    height: 100%;
    font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-weight: 400;
    overflow-x: hidden;
    overflow-y: auto;
}

.Layout .content-foodzaps {
    padding-top: 4%;
    padding-bottom: 4%;
    background-color: #d2d6de;
}

.fade {
    opacity: unset
}

@media screen and (max-width: 768px) {
    .Layout .content-foodzaps {
        padding-top: 26%;
        padding-bottom: 10%;
    }     
}