.Filter .filter-detail {
    position: absolute;
    top: 40px;
    right: 0;
    border-radius: 5px 0 5px 5px;
    background: #3c8dbc;
    width: 535px;
    font-size: 14px;
    z-index: 100;
    padding: 10px;
}

.Filter .filter-detail .filter-footer {
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
}

@media screen and (max-width: 768px) {
    .Filter .filter-detail {
        width: 100%;
        margin-bottom: 80px;
    }
    .Filter .filter-detail .filter-footer button{
        width: 90%;
    }
    .Filter .filter-detail .FilterItem{
        padding-bottom: 10px;
    }
    .Filter .filter-detail .FilterItem .btn-danger{
        width: 100%;
    }
}