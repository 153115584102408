.Fieldable .fieldable-detail {
    position: absolute;
    top: 40px;
    right: 0;
    border-radius: 5px 0 5px 5px;
    background: white;
    width: 200px;
    font-size: 14px;
    z-index: 100;
    padding: 10px;
    border: 1px solid #3c8dbc;
}

.Fieldable input[type=checkbox] {
    background-color: green
}

.Fieldable .fieldable-detail table {
    /* color: white */
}

.Fieldable .fieldable-detail .fieldable-footer {
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
}