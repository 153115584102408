.Export .export-detail {
    position: absolute;
    top: 40px;
    right: 10px;
    border-radius: 5px 0 5px 5px;
    background: #3c8dbc;
    width: 355px;
    font-size: 14px;
    z-index: 100;
    padding: 10px;
}

.Export .row {
    margin: 0 5px;
}

.Export .export-detail .export-footer {
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
}
.Export .export-detail .export-footer button{
    width: 90%;
}

.Export .fields-can-export {
    padding: 10px 5px;
    background: white;
    margin-bottom: 15px;
    border-radius: 10px;
}

.Export .fields-can-export .red {
    color: red
}

.Export .fields-can-export .green {
    color: green
}

.Export .info {
    color: white;
}

@media screen and (max-width: 768px) {
    .Export .export-detail {
        width: 100%;
        margin-bottom: 80px;
        right: 0;
    }
    .Export .export-detail .export-footer button{
        width: 90%;
    }
    .Export .export-detail .row{
        padding-bottom: 10px;
        overflow: scroll;
        height: 200px;
    }
    .Export .export-detail .row.Setting{
        padding-bottom: 0;
        overflow: scroll;
        height: unset;
    }
    .Export .export-detail .btn-success{
        width: 100%;
    }
    .Export .export-detail .NoPage {
        height: 80px;
    }
    .Export .export-detail .MessageResponse {
        height: 100px;
    }
}