/* The popup form - hidden by default */
.MoreButtonPopup .popup_image.form-popup {
    display: block;
    position: fixed;
    right: 20%;
    bottom: 25%;
    border: 3px solid #f1f1f1;
    z-index: 400;
    width: 50%;
    height: 500px;
    background: white;
}

/* Add styles to the form container */
.MoreButtonPopup .popup_image .form-container {
    padding: 10px;
    background-color: white;
}

.MoreButtonPopup .popup_image .form-container .popup_footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    padding: 10px;
    border-top: 1px solid lightgrey;
}

.MoreButtonPopup .popup_image .form-container .popup_header {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    height: 50px;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
}

.MoreButtonPopup .popup_image .form-container .popup_header h3 {
    margin-top: 3px;
}

.MoreButtonPopup .popup_image .form-container .popup_body {
    position: absolute;
    top: 50px;
    bottom: 60px;
    width: 100%;
    font-weight: 300;
    overflow: auto;
    left: 0;
    text-align: left;
    margin-top: 20px;
}

.MoreButtonPopup .popup_image_bover {
    background: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
}

.MoreButtonPopup .popup_body img.PreviewImage {
    width: 350px;
    max-height: 350px;
    margin: 1% 0%!important;
}

.MoreButtonPopup .popup_body .divPreviewImage {
    text-align: center
}

.MoreButtonPopup .text-block {
    position: absolute;
    bottom: 0;
    background-color: rgba(0,0,0,.4);
    color: white;
    max-width: 240px;
    line-height: 30px;
    width: 100%;
    font-weight: bold;
}

.MoreButtonPopup {
    padding-bottom: 30px;
}

.MoreButtonPopup .imgBox {
    position: relative;
    max-width: 240px;
    max-height: 240px;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .MoreButtonPopup .popup_image.form-popup {
        transform: translate3d(0,0,0);
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
    .MoreButtonPopup .popup_image .form-container .popup_footer {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        position: absolute;
        z-index: 2000;
    }
    
    .MoreButtonPopup .popup_image .form-container .popup_header {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        position: absolute;
        z-index: 2000;
    }
    .MoreButtonPopup .popup_image .form-container .popup_header h3 {
        font-size: 18px    
    }

    .MoreButtonPopup .popup_image.form-popup {
        z-index: 2000;
    }

    .MoreButtonPopup .popup_image_bover {
        z-index: 2000;
    }

    .MoreButtonPopup .btn-group {
        display: flex;
    }

    .MoreButtonPopup .text-block {
        position: absolute;
        bottom: 0;
        width: 100%;
        background-color: rgba(0,0,0,.4);
        color: white;
        max-width: 240px;
        line-height: 30px;
        font-weight: bold;
    }

    .MoreButtonPopup .popup_footer .buttonFooter{
        padding-left: 5px;
        padding-right: 5px;
    }
}
