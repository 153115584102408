
.modal.fade .modal-dialog.modal-full {
    top: 25%;
}

.modal.fade .modal-dialog {
    top: 15%;
}

.modal-dialog.modal-full {
    position: fixed;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0;
}

.modal-full .modal-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid #3c8dbc;
    border-radius: 0;
    box-shadow: none;
}

.modal-full .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 50px;
    padding: 10px;
    background: #3c8dbc;
    border: 0;
}

.modal-full .modal-title {
    font-weight: 300;
    font-size: 2em;
    color: #fff;
    line-height: 30px;
}

.modal-full .modal-body {
    position: absolute;
    top: 50px;
    bottom: 60px;
    width: 100%;
    font-weight: 300;
    overflow: auto;
}

.modal-full .modal-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    padding: 10px;
    background: #f1f3f5;
}
.nav-tabs>li>a.active, .nav-tabs>li>a.active:focus, .nav-tabs>li>a.active:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}
.skin-blue .sidebar-menu>li>a.active {
    border-left-color: #3c8dbc;
}
.skin-blue .sidebar-menu .treeview-menu>li>a.active, .skin-blue .sidebar-menu .treeview-menu>li>a:hover {
    color: #fff;
}

/* Progress Circle */
.sprogress-circle {
    font-size: 6px;
    margin: 5px 45%;
    position: relative; /* so that children can be absolutely positioned */
    padding: 0;
    width: 5em;
    height: 5em;
    background-color: lightgray;
    border-radius: 50%;
    line-height: 5em;
 }
 
 .sprogress-circle:after{
     border: none;
     position: absolute;
     top: 0.35em;
     left: 0.35em;
     text-align: center;
     display: block;
     border-radius: 50%;
     width: 4.3em;
     height: 4.3em;
     /* background-color: white; */
     content: " ";
 }
 /* Text inside the control */
 .sprogress-circle span {
     position: absolute;
     line-height: 5em;
     width: 5em;
     text-align: center;
     display: block;
     color: #337ab7;
     z-index: 2;
 }
 .left-half-clipper { 
    /* a round circle */
    border-radius: 50%;
    width: 5em;
    height: 5em;
    position: absolute; /* needed for clipping */
    clip: rect(0, 5em, 5em, 2.5em); /* clips the whole left half*/ 
 }
 /* when p>50, don't clip left half*/
 .sprogress-circle.over50 .left-half-clipper {
    clip: rect(auto,auto,auto,auto);
 }
 .value-bar {
    /*This is an overlayed square, that is made round with the border radius,
    then it is cut to display only the left half, then rotated clockwise
    to escape the outer clipping path.*/ 
    position: absolute; /*needed for clipping*/
    clip: rect(0, 2.5em, 5em, 0);
    width: 5em;
    height: 5em;
    border-radius: 50%;
    border: 2.45em solid #337ab7; /*The border is 0.35 but making it larger removes visual artifacts */
    /*background-color: #4D642D;*/ /* for debug */
    box-sizing: border-box;
   
 }
 /* Progress bar filling the whole right half for values above 50% */
 .sprogress-circle.over50 .first50-bar {
    /*Progress bar for the first 50%, filling the whole right half*/
    position: absolute; /*needed for clipping*/
    clip: rect(0, 5em, 5em, 2.5em);
    background-color: #337ab7;
    border-radius: 50%;
    width: 5em;
    height: 5em;
 }
 .sprogress-circle:not(.over50) .first50-bar{ display: none; }
 
 
 /* Progress bar rotation position */
 .sprogress-circle.p0 .value-bar { display: none; }
 .sprogress-circle.p1 .value-bar { transform: rotate(4deg); }
 .sprogress-circle.p2 .value-bar { transform: rotate(7deg); }
 .sprogress-circle.p3 .value-bar { transform: rotate(11deg); }
 .sprogress-circle.p4 .value-bar { transform: rotate(14deg); }
 .sprogress-circle.p5 .value-bar { transform: rotate(18deg); }
 .sprogress-circle.p6 .value-bar { transform: rotate(22deg); }
 .sprogress-circle.p7 .value-bar { transform: rotate(25deg); }
 .sprogress-circle.p8 .value-bar { transform: rotate(29deg); }
 .sprogress-circle.p9 .value-bar { transform: rotate(32deg); }
 .sprogress-circle.p10 .value-bar { transform: rotate(36deg); }
 .sprogress-circle.p11 .value-bar { transform: rotate(40deg); }
 .sprogress-circle.p12 .value-bar { transform: rotate(43deg); }
 .sprogress-circle.p13 .value-bar { transform: rotate(47deg); }
 .sprogress-circle.p14 .value-bar { transform: rotate(50deg); }
 .sprogress-circle.p15 .value-bar { transform: rotate(54deg); }
 .sprogress-circle.p16 .value-bar { transform: rotate(58deg); }
 .sprogress-circle.p17 .value-bar { transform: rotate(61deg); }
 .sprogress-circle.p18 .value-bar { transform: rotate(65deg); }
 .sprogress-circle.p19 .value-bar { transform: rotate(68deg); }
 .sprogress-circle.p20 .value-bar { transform: rotate(72deg); }
 .sprogress-circle.p21 .value-bar { transform: rotate(76deg); }
 .sprogress-circle.p22 .value-bar { transform: rotate(79deg); }
 .sprogress-circle.p23 .value-bar { transform: rotate(83deg); }
 .sprogress-circle.p24 .value-bar { transform: rotate(86deg); }
 .sprogress-circle.p25 .value-bar { transform: rotate(90deg); }
 .sprogress-circle.p26 .value-bar { transform: rotate(94deg); }
 .sprogress-circle.p27 .value-bar { transform: rotate(97deg); }
 .sprogress-circle.p28 .value-bar { transform: rotate(101deg); }
 .sprogress-circle.p29 .value-bar { transform: rotate(104deg); }
 .sprogress-circle.p30 .value-bar { transform: rotate(108deg); }
 .sprogress-circle.p31 .value-bar { transform: rotate(112deg); }
 .sprogress-circle.p32 .value-bar { transform: rotate(115deg); }
 .sprogress-circle.p33 .value-bar { transform: rotate(119deg); }
 .sprogress-circle.p34 .value-bar { transform: rotate(122deg); }
 .sprogress-circle.p35 .value-bar { transform: rotate(126deg); }
 .sprogress-circle.p36 .value-bar { transform: rotate(130deg); }
 .sprogress-circle.p37 .value-bar { transform: rotate(133deg); }
 .sprogress-circle.p38 .value-bar { transform: rotate(137deg); }
 .sprogress-circle.p39 .value-bar { transform: rotate(140deg); }
 .sprogress-circle.p40 .value-bar { transform: rotate(144deg); }
 .sprogress-circle.p41 .value-bar { transform: rotate(148deg); }
 .sprogress-circle.p42 .value-bar { transform: rotate(151deg); }
 .sprogress-circle.p43 .value-bar { transform: rotate(155deg); }
 .sprogress-circle.p44 .value-bar { transform: rotate(158deg); }
 .sprogress-circle.p45 .value-bar { transform: rotate(162deg); }
 .sprogress-circle.p46 .value-bar { transform: rotate(166deg); }
 .sprogress-circle.p47 .value-bar { transform: rotate(169deg); }
 .sprogress-circle.p48 .value-bar { transform: rotate(173deg); }
 .sprogress-circle.p49 .value-bar { transform: rotate(176deg); }
 .sprogress-circle.p50 .value-bar { transform: rotate(180deg); }
 .sprogress-circle.p51 .value-bar { transform: rotate(184deg); }
 .sprogress-circle.p52 .value-bar { transform: rotate(187deg); }
 .sprogress-circle.p53 .value-bar { transform: rotate(191deg); }
 .sprogress-circle.p54 .value-bar { transform: rotate(194deg); }
 .sprogress-circle.p55 .value-bar { transform: rotate(198deg); }
 .sprogress-circle.p56 .value-bar { transform: rotate(202deg); }
 .sprogress-circle.p57 .value-bar { transform: rotate(205deg); }
 .sprogress-circle.p58 .value-bar { transform: rotate(209deg); }
 .sprogress-circle.p59 .value-bar { transform: rotate(212deg); }
 .sprogress-circle.p60 .value-bar { transform: rotate(216deg); }
 .sprogress-circle.p61 .value-bar { transform: rotate(220deg); }
 .sprogress-circle.p62 .value-bar { transform: rotate(223deg); }
 .sprogress-circle.p63 .value-bar { transform: rotate(227deg); }
 .sprogress-circle.p64 .value-bar { transform: rotate(230deg); }
 .sprogress-circle.p65 .value-bar { transform: rotate(234deg); }
 .sprogress-circle.p66 .value-bar { transform: rotate(238deg); }
 .sprogress-circle.p67 .value-bar { transform: rotate(241deg); }
 .sprogress-circle.p68 .value-bar { transform: rotate(245deg); }
 .sprogress-circle.p69 .value-bar { transform: rotate(248deg); }
 .sprogress-circle.p70 .value-bar { transform: rotate(252deg); }
 .sprogress-circle.p71 .value-bar { transform: rotate(256deg); }
 .sprogress-circle.p72 .value-bar { transform: rotate(259deg); }
 .sprogress-circle.p73 .value-bar { transform: rotate(263deg); }
 .sprogress-circle.p74 .value-bar { transform: rotate(266deg); }
 .sprogress-circle.p75 .value-bar { transform: rotate(270deg); }
 .sprogress-circle.p76 .value-bar { transform: rotate(274deg); }
 .sprogress-circle.p77 .value-bar { transform: rotate(277deg); }
 .sprogress-circle.p78 .value-bar { transform: rotate(281deg); }
 .sprogress-circle.p79 .value-bar { transform: rotate(284deg); }
 .sprogress-circle.p80 .value-bar { transform: rotate(288deg); }
 .sprogress-circle.p81 .value-bar { transform: rotate(292deg); }
 .sprogress-circle.p82 .value-bar { transform: rotate(295deg); }
 .sprogress-circle.p83 .value-bar { transform: rotate(299deg); }
 .sprogress-circle.p84 .value-bar { transform: rotate(302deg); }
 .sprogress-circle.p85 .value-bar { transform: rotate(306deg); }
 .sprogress-circle.p86 .value-bar { transform: rotate(310deg); }
 .sprogress-circle.p87 .value-bar { transform: rotate(313deg); }
 .sprogress-circle.p88 .value-bar { transform: rotate(317deg); }
 .sprogress-circle.p89 .value-bar { transform: rotate(320deg); }
 .sprogress-circle.p90 .value-bar { transform: rotate(324deg); }
 .sprogress-circle.p91 .value-bar { transform: rotate(328deg); }
 .sprogress-circle.p92 .value-bar { transform: rotate(331deg); }
 .sprogress-circle.p93 .value-bar { transform: rotate(335deg); }
 .sprogress-circle.p94 .value-bar { transform: rotate(338deg); }
 .sprogress-circle.p95 .value-bar { transform: rotate(342deg); }
 .sprogress-circle.p96 .value-bar { transform: rotate(346deg); }
 .sprogress-circle.p97 .value-bar { transform: rotate(349deg); }
 .sprogress-circle.p98 .value-bar { transform: rotate(353deg); }
 .sprogress-circle.p99 .value-bar { transform: rotate(356deg); }
 .sprogress-circle.p100 .value-bar { transform: rotate(360deg); }

.react-bootstrap-table table {
    table-layout: auto;
}

.fzTab .nav-tabs {
    border: none;
    height: 50px;
    overflow-x: scroll;
    overflow-y: none;
    overflow: auto;
    white-space: nowrap;
}

.fzTab .nav-tabs li {
    float: none;
    display: inline-block;
}

.fzTab .active a {
    border: none;
}

.fzTab ::-webkit-scrollbar {
    height: 4px;
}

.fzTab ::webkit-scrollbar:vertical {
    width: 0px;
}

.fzTab ::-webkit-scrollbar-thumb {
    background: #ccc;
}

.fzTab {
    overflow: hidden;
}

#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content {
    max-height: 370px;
    overflow-y: auto;
    overflow-x: hidden    
}

#fm-toolbar-wrapper #fm-toolbar .fm-dropdown-content li {
    width: 200px;
}

.margin-end {
    margin: 10px 0;
}

.margin-start {
    margin: 10px 10px 10px 0;
}

.Sidebar .sidebar-menu .treeview-menu > li > a.active i {
    color: #3c8dbc;
    background-color: #3c8dbc;
    border-radius: 50%;
    width: 14px;
    margin-right: 7px;
    margin-left: -1px;
}

.choose_data_import .form-check{
    text-align: left
}
.choose_data_import .form-check .form-check-label {
    width: 80%;
}

.choose_data_import input[type="checkbox"], .choose_data_import input[type="radio"] {
    margin: 10px;
}
.margin5 {
    margin: 5px
}

.swal2-container {
    z-index: 10500000002!important;
}

.modal {
    z-index: 10500000001!important;
}

@media screen and (max-width: 768px) {
    .pagination > li > a, .pagination > li > span {
        padding: 6px 8px;
        font-size: 12px;
    }
    .react-bs-table-container .react-bs-table-pagination .row .col-xs-6:first-child{
        display: none;
    }
    .react-bs-table-container .react-bs-table-pagination .row .col-xs-6{
        width: 100%;
    }
    .react-bootstrap-table-page-btns-ul {
        float: left!important;
    }    
    #wdr-component #fm-pivot-view #fm-fields-view.fm-flat-fields, #fm-pivot-view #fm-fields-view.fm-pivot-fields {
        position: fixed!important;
        width: 100%!important;
        height: 100%!important;
        overflow-x: scroll; 
        min-width: unset;  
        top: 0!important;
    }
    #wdr-component .fm-ui-element.fm-ui.fm-popup-content.fm-ui-mobile {
        min-width: 500px;
        margin: 0 10px 0 0;
    }
    #wdr-component #fm-pivot-view #fm-fields-view .fm-popup-content .fm-ui-row {
        padding-right: 20px;
    }
    #wdr-component #fm-toolbar-wrapper #fm-popup-options, #fm-toolbar-wrapper #fm-popup-format-cells {
        position: fixed!important;
        top: 0!important;
        left: 0!important;
        right: 0!important;
        height: 400px;
        overflow: scroll;
    }
    #wdr-component #fm-toolbar-wrapper #fm-popup-conditional {
        position: fixed!important;
        width: 100%!important;
        height: 100%!important; 
        top: 0px!important;
        right: 0!important;
        min-width: unset;
    }
    #wdr-component #fm-popup-format-cells {
        overflow: scroll;
        top: 0!important;
        left: 0!important;
        right: 0!important;
        height: 400px;
    }
    #wdr-component #fm-toolbar-wrapper div.fm-panel .fm-panel-content {
        height: 550px!important;
    }
    #wdr-component #fm-toolbar-wrapper .fm-toolbar-ui .fm-inp-group:last-child {
        padding-bottom: 100px;
    }
    #wdr-component #fm-pivot-view #fm-fields-view .fm-popup-content .fm-list-wrap-all .fm-list-content {
        -webkit-overflow-scrolling: touch;
    }
    #fm-pivot-view .fm-draggable {
        touch-action: unset; 
    }
    #fm-pivot-view .fm-grid-layout div.fm-scroll-pane {
        overflow: scroll;
    }

    /* #fm-rows-sheet {
        -webkit-overflow-scrolling: touch;
    } */

    /* #fm-pivot-view .fm-grid-layout div.fm-scroll-pane  {
        overflow: auto
    } */

    /* #fm-data-sheet {
        overflow: auto
    } */
    /* .modal-dialog.modal-full {
        position: relative;
    }
    .modal-open .modal {
        overflow-x: hidden;
        overflow-y: hidden;
    }
    #fm-pivot-view {
        overflow: auto;
    } */

    /* #fm-pivot-view .fm-grid-layout div.fm-scroll-pane div.fm-scroll-content {
        overflow: auto
    } */
    #fm-grid-view {
        position: relative;
    }
    .modal {
        -webkit-overflow-scrolling: unset;
    }
    .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
        right: 0;
        left: 0;
    }
    .todo-list > li .text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 70%;
        padding-left: 5px;
    }

    .daterangepicker {
        position: fixed;
        top: 0!important;
        right: 0!important;
        bottom: 0!important;
        left: 0!important;
        outline: 0;
        z-index: 10500000002!important;
        overflow-x: hidden;
        width: 100%;
        margin-top: 0px;
        overflow-y: auto;
    }

    .daterangepicker .drp-calendar {
        max-width: unset;
    }
}