.Menu.scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.Menu {
    /* background-color: #f4f4f4; */
    margin-top: 10px; 
}
  
.Menu {
    border-radius: 3px;
}

.Menu .nav-item {
    padding: 12px 16px 13px;
    color: black;
}
.Menu .nav-item:not(:last-child) {
    border-right: 1px solid rgba(114, 124, 135, 0.2);
}
  
.Menu {
    box-sizing: border-box;
}
  
.Menu {
    font-family: -apple-system, BlinkMacSystemFont,  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",  "Fira Sans", "Droid Sans", "Helvetica Neue",  sans-serif;
    /* max-width: 360px; */
    width: 100%;
    line-height: 1.5;
}
  
.Menu,
.Menu nav {
    font-size: 0;
}
  
.Menu .logo,
.Menu .nav-item {
    font-size: 14px;
    background-color: #f4f4f4;
    cursor: pointer;
}

.Menu .nav-item.active {
    color: #fff;
    background-color: #286090;
}
  
.Menu .logo,
.Menu .nav-item,
.Menu .vertical-align-middle {
    display: inline-block;
    vertical-align: middle;
  }
  
.Menu .title {
    margin: 24px 0 6px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .2em;
    color: #999;
}
  
.Menu.scroll::-webkit-scrollbar {
    display: none;
}