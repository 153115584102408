.Table .box-action-group {
    float: right
}
.Table .box-title span {
    font-size: 12px;
}
.Table .pt-5 {
    padding-top: 5px;
}
.Table .box-title {
    float: left
}
.Table .box-action-group a i{
    padding: 5px
}
.Table .box-action-group a.active{
    color: white;
    background: #3c8dbc;
    padding: 10px;
    border-radius: 5px 5px 0 0;
}
.Table .box-action-group a.active .separate{
    display: none;
}
.Table .separate {
    display: inline-block;
    height: 18px;
    width: 1px;
    background: #66637A;
    vertical-align: bottom;
    margin: 0 11px;
}

.Table .table-container {
    width: 100%;
    /* overflow-x: scroll; */
    /* border: 1px solid lightgrey; */
}

.Table .table-container tbody {
    overflow-y: scroll;
    display: block;
    height: calc(67vh - 1px);
    min-height: calc(200px + 1px);
}

.Table .table-container tbody.small {
    height: calc(20vh - 1px);
}

.Table .table-container thead {
    display: table-row;
}

.Table .table-container table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 0;
}
.Table .table-container th,.Table .table-container td {
    border: 1px solid #bbb;
    min-width: 200px;
    padding: 8px 16px;
    overflow: hidden;
    max-width: 200px;
    position: relative;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
}
.Table .table-container th {
    background-color: darkgray;
    border-width: 1px;
}
.Table .table-container td {
    border-width: 1px;
}
.Table .table-container tr:first-child td {
    border-top-width: 0;
}
.Table .table-container tr:nth-child(even) {
    background-color: #eee;
}

.Table .table-container th.table-action, .table-container td.table-action {
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    padding-left: 0;
}
/* .Table .table-container th:nth-child(1), .table-container td:nth-child(1) {
    min-width: 50px;
    text-align: center;
    padding-left: 0;
} */

.Table .loadingmore {
    text-align: center;
}

.Table .loadingmore img {
    width: 47px;
}

.Table .box-title {
    width: 50%;
}

.Table .box {
    margin-bottom: 0;
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    left: 0;
}

.Table .box-body {
    position: absolute;
    top: 50px;
    bottom: 60px;
    width: 100%;
    /* overflow: auto; */
}

.Table .box-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
}