.NoData {
  	text-align: center
}
.NoDataMenuInfo p {
  	font-size: 15px;
}
.NoDataMenuMoreInfo {
	text-align: center;
	margin-top: 20px;
}
.NoData.NoDataMenuImage img {
  	max-width: 70%;
}

@media screen and (max-width: 768px) {
	.NoData.NoDataMenuImage img {
		max-width: 100%;
	}
	.NoDataMenuInfo h3 {
		font-size: 17px;
		line-height: 25px;
	}
	.NoDataMenuInfo p {
		font-size: 13px;
  	}
}